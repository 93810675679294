import React, { useState, useEffect, useCallback } from 'react'
import { Table, Pagination, Select, Button, Input, Tag, Modal } from 'antd'
import { API } from '../../constant'
import axios from 'axios'
import { getToken } from '../../helpers'
import { UpOutlined, DownOutlined } from '@ant-design/icons'

const CustomerList = () => {
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  })
  const [sortOrder, setSortOrder] = useState('desc')
  const [tags, setTags] = useState([])
  const [newTag, setNewTag] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const pageSizeOptions = [20, 30, 50, 100]
  const handleRowClick = record => {
    // Chuyển hướng sang trang mới với id của khách hàng
    // navigate(`/detail-customer/${record.id}`)
  }
  // Function to get token
  const getAuthToken = () => `Bearer ${getToken()}`

  // Fetch customers using the search API with useCallback to memoize the function
  const fetchCustomers = useCallback(
    async (page, pageSize, sortOrder, searchTerm) => {
      setLoading(true)
      const token = getAuthToken()

      try {
        // Gọi API tìm kiếm
        const response = await axios.get(`${API}/customers/search`, {
          params: {
            'pagination[page]': page,
            'pagination[pageSize]': pageSize,
            sort: `totalInvoiced:${sortOrder}`,
            search: searchTerm // Từ khóa tìm kiếm
          },
          headers: {
            Authorization: token
          }
        })
        setCustomers(response.data.data)
        setPagination({
          current: page,
          pageSize,
          total: response.data.meta.pagination.total // Tổng số khách hàng
        })
      } catch (error) {
        console.error('Error fetching customers:', error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // Load customers when component is mounted or when pagination/sort/search changes
  useEffect(() => {
    fetchCustomers(
      pagination.current,
      pagination.pageSize,
      sortOrder,
      searchTerm
    )
  }, [
    pagination.current,
    pagination.pageSize,
    sortOrder,
    searchTerm,
    fetchCustomers
  ])

  const handleTableChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize
    })
  }

  const handlePageSizeChange = (current, size) => {
    setPagination({
      ...pagination,
      pageSize: size,
      current: 1 // Đặt lại current về 1 khi thay đổi pageSize
    })
  }

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc'
    setSortOrder(newSortOrder)
  }

  const handleTagInputChange = e => {
    setNewTag(e.target.value)
  }

  const handleTagAdd = () => {
    if (newTag.trim() && selectedCustomer) {
      const token = getAuthToken()

      axios
        .post(
          `${API}/customer-tags`,
          {
            data: {
              title: newTag.trim()
            }
          },
          {
            headers: { Authorization: token }
          }
        )
        .then(response => {
          const newTagId = response.data.data.id
          var customer_tags = selectedCustomer.customer_tags.data.map(
            item => item.id
          )
          customer_tags.push(newTagId)
          return axios.put(
            `${API}/customers/${selectedCustomer.id}?populate=customer_tags`,
            {
              data: {
                customer_tags: customer_tags
              }
            },
            {
              headers: { Authorization: token }
            }
          )
        })
        .then(response => {
          setTags([...tags, newTag.trim()])
          setNewTag('')
          setIsModalVisible(false)
        })
        .catch(error => {
          console.error('Error adding tag:', error)
        })
    }
  }

  const handleTagRemove = tag => {
    setTags(tags.filter(t => t !== tag))
  }

  const handleCustomerClick = customer => {
    setSelectedCustomer(customer)
    setIsModalVisible(true)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const handleModalOk = () => {
    handleTagAdd()
  }

  const handleSearch = e => {
    setSearchTerm(e.target.value)
    setPagination({ ...pagination, current: 1 }) // Đặt lại current về 1 khi tìm kiếm
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px'
        }}
      >
        <Input
          placeholder="Search customers"
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: '200px', marginRight: '10px' }}
        />

        <Select
          defaultValue={pagination.pageSize}
          style={{ width: 120 }}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map(size => (
            <Select.Option key={size} value={size}>
              {size} items/page
            </Select.Option>
          ))}
        </Select>

        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          showSizeChanger={false}
          onChange={handleTableChange}
        />
      </div>

      <Table
        rowKey="id"
        dataSource={customers}
        loading={loading}
        pagination={false}
        columns={[
          {
            title: 'Code',
            key: 'code',
            render: (text, record) => record.code
          },
          {
            title: 'Name',
            key: 'name',
            render: (text, record) => (
              <a onClick={() => handleCustomerClick(record)}>{record.name}</a>
            )
          },
          {
            title: (
              <span>
                Total Invoiced
                <Button
                  type="link"
                  icon={
                    sortOrder === 'desc' ? <UpOutlined /> : <DownOutlined />
                  }
                  onClick={toggleSortOrder}
                />
              </span>
            ),
            key: 'totalInvoiced',
            render: (text, record) => {
              const totalInvoiced = record.totalInvoiced ?? 0 // Nếu undefined hoặc null thì gán bằng 0
              return totalInvoiced.toLocaleString() // Hiển thị với dấu phân cách
            },
            align: 'right'
          }
        ]}
        onRow={record => ({
          onClick: () => handleRowClick(record)
        })}
      />

      <Modal
        title="Add Tag to Customer"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          value={newTag}
          onChange={handleTagInputChange}
          onPressEnter={handleTagAdd}
          placeholder="Type tag and press Enter"
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div>
          {tags.map(tag => (
            <Tag key={tag} closable onClose={() => handleTagRemove(tag)}>
              {tag}
            </Tag>
          ))}
        </div>
      </Modal>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '10px'
        }}
      >
        <Select
          defaultValue={pagination.pageSize}
          style={{ width: 120 }}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map(size => (
            <Select.Option key={size} value={size}>
              {size} items/page
            </Select.Option>
          ))}
        </Select>

        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          showSizeChanger={false}
          onChange={handleTableChange}
        />
      </div>
    </>
  )
}

export default CustomerList
