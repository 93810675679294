import React, { useState } from 'react'
import { Upload, Button, Table, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'
import { checkPhones, addCustomer } from '../../api/customerApi' // Đường dẫn tới file API
import './ImportExcelPage.css' // Import file CSS cho style

const ImportExcelPage = () => {
  const [fileData, setFileData] = useState([])
  const [fileName, setFileName] = useState('')
  const [matchedPhones, setMatchedPhones] = useState({}) // Lưu số điện thoại trùng
  const [resultStatuses, setResultStatuses] = useState({}) // Lưu kết quả trạng thái

  // Xử lý file Excel khi người dùng chọn file
  const handleFile = file => {
    setFileName(file.name)

    const reader = new FileReader()
    reader.onload = e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const sheetName = workbook.SheetNames[0] // Lấy tên sheet đầu tiên
      const sheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet)

      setFileData(jsonData) // Lưu dữ liệu từ file
    }
    reader.readAsBinaryString(file)

    return false // Ngăn không cho file được tải ngay lập tức
  }

  // Kiểm tra số điện thoại trùng
  const handleCheckDuplicates = () => {
    const phones = fileData.map(entry => entry.phone) // Lấy tất cả số điện thoại từ file Excel

    checkPhones(phones)
      .then(response => {
        const phoneMap = {}
        response.forEach(customer => {
          customer.phones.forEach(phone => {
            if (phones.includes(phone.numberPhone)) {
              phoneMap[phone.numberPhone] = {
                name: customer.name,
                code: customer.code
              }
            }
          })
        })
        setMatchedPhones(phoneMap) // Lưu kết quả trùng vào state
        if (Object.keys(phoneMap).length > 0) {
          message.warning('Found duplicate phone numbers')
        } else {
          message.success('No duplicates found')
        }
      })
      .catch(() => {
        message.error('Error checking phone numbers')
      })
  }

  // Thêm dữ liệu từng khách hàng vào hệ thống
  const handleAddCustomers = async () => {
    const statuses = {}

    for (const [index, customer] of fileData.entries()) {
      try {
        // Chuyển đổi trường phone thành phones: [{numberPhone: phone}]
        const customerData = {
          ...customer, // Lấy các trường khác từ dữ liệu file
          phones: customer.phone ? [{ numberPhone: `${customer.phone}` }] : [] // Chuyển đổi phone thành cấu trúc phones
        }

        delete customerData.phone // Xóa trường phone cũ sau khi đã chuyển thành phones

        await addCustomer({
          data: customerData
        }) // Gọi API để thêm từng khách hàng
        statuses[index] = 'success' // Lưu trạng thái thành công
      } catch (error) {
        statuses[index] = 'danger' // Lưu trạng thái thất bại
      }
    }

    setResultStatuses(statuses) // Cập nhật kết quả vào state
  }

  // Cấu hình cho component Upload
  const uploadProps = {
    beforeUpload: handleFile
  }

  // Định nghĩa các cột cho bảng
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Log',
      dataIndex: 'log',
      key: 'log'
    },
    {
      title: 'Matched Customer Name',
      key: 'matchedName',
      render: (_, record) => matchedPhones[record.phone]?.name || 'No Match'
    },
    {
      title: 'Matched Customer Code',
      key: 'matchedCode',
      render: (_, record) => matchedPhones[record.phone]?.code || 'No Match'
    },
    {
      title: 'Result',
      key: 'result',
      render: (_, record, index) => resultStatuses[index] || 'Pending' // Hiển thị kết quả thêm
    }
  ]

  // Hàm để thêm class vào hàng nếu số điện thoại trùng, thành công hoặc thất bại
  const rowClassName = (record, index) => {
    if (resultStatuses[index] === 'success') return 'row-success'
    if (resultStatuses[index] === 'danger') return 'row-danger'
    return matchedPhones[record.phone] ? 'row-warning' : ''
  }

  return (
    <div>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <p>Selected file: {fileName}</p>
      <Button
        type="primary"
        onClick={handleCheckDuplicates}
        style={{ marginTop: 16 }}
      >
        Check for Duplicates
      </Button>
      <Button
        type="primary"
        onClick={handleAddCustomers}
        style={{ marginTop: 16, marginLeft: 8 }}
      >
        Add Customers to System
      </Button>

      {fileData.length > 0 && (
        <Table
          columns={columns}
          dataSource={fileData.map((item, index) => ({
            ...item,
            key: index
          }))}
          pagination={false}
          rowClassName={rowClassName} // Áp dụng class cho hàng
        />
      )}
    </div>
  )
}

export default ImportExcelPage
