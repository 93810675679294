import React from 'react'
import { Layout, Menu, Dropdown, Avatar, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { removeToken } from '../../helpers' // Import hàm removeToken

const { Header } = Layout

const menuItems = [
  { key: 'home', label: 'Tổng quan', path: '/' },
  // { key: 'customer', label: 'Khách hàng', path: '/customer' },
  // { key: 'campaign', label: 'Chiến dịch', path: '/campaign' },
  // { key: 'product', label: 'Sản phẩm', path: '/product' },
  {
    key: 'import-prospect',
    label: 'Thêm khách',
    path: '/import-prospect'
  },
  { key: 'prospect', label: 'Thẩm định', path: '/prospect' }
]

export default function Headers() {
  const navigate = useNavigate()

  const handleClick = e => {
    const selectedItem = menuItems.find(item => item.key === e.key)
    if (selectedItem) {
      navigate(selectedItem.path)
    }
  }

  const handleMenuClick = e => {
    if (e.key === 'profile') {
      navigate('/profile')
    } else if (e.key === 'logout') {
      removeToken() // Gọi hàm xóa token
      navigate('/signin') // Điều hướng đến trang đăng nhập
    }
  }

  const userMenu = {
    items: [
      { key: 'profile', label: 'Profile' },
      { key: 'logout', label: 'Logout' }
    ],
    onClick: handleMenuClick
  }

  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['customer']}
          items={menuItems}
          onClick={handleClick}
          style={{
            flex: 1,
            minWidth: 0
          }}
        />
      </div>
      <Space style={{ marginRight: 16 }}>
        <Dropdown menu={userMenu} trigger={['click']}>
          <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
        </Dropdown>
      </Space>
    </Header>
  )
}
