import React from 'react'
import AppHeader from '../components/App/Header'
import AppFooter from '../components/App/Footer'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
const LayoutApp = () => {
  return (
    <Layout>
      <AppHeader />
      <Outlet />
      <AppFooter />
    </Layout>
  )
}

export default LayoutApp
