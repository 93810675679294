import React, { useState, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { message } from 'antd'
import { API, BEARER } from '../../constant'
import { getToken } from '../../helpers'
import { useNavigate, useLocation } from 'react-router-dom'

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userData')) || null // Lấy user từ localStorage khi khởi động
  )
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation() // Sử dụng useLocation để lấy đường dẫn hiện tại

  const authToken = getToken(navigate) // Truyền navigate vào getToken

  const fetchLoggedInUser = async token => {
    setIsLoading(true)
    try {
      const response = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` }
      })
      const data = await response.json()

      setUserData(data)
      localStorage.setItem('userData', JSON.stringify(data)) // Lưu thông tin user vào localStorage
    } catch (error) {
      console.error(error)
      message.error('Error While Getting Logged In User Details')
    } finally {
      setIsLoading(false)
    }
  }

  const handleUser = user => {
    setUserData(user)
    localStorage.setItem('userData', JSON.stringify(user)) // Lưu thông tin user vào localStorage khi được cập nhật
  }

  const logout = () => {
    localStorage.removeItem('userData') // Xóa thông tin user khi logout
    localStorage.removeItem('jwtToken') // Xóa token khi logout
    setUserData(null)
    navigate('/signin', { replace: true })
  }

  useEffect(() => {
    if (!authToken && location.pathname !== '/signin') {
      // Chỉ chuyển hướng nếu người dùng không có token và không đang ở trang đăng nhập
      navigate('/signin', { replace: true })
    } else if (authToken) {
      fetchLoggedInUser(authToken)
    }
  }, [authToken, location.pathname, navigate]) // Lắng nghe thay đổi của authToken và location.pathname

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, isLoading, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
