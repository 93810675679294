import React from 'react'
import { Row, Col } from 'antd'

const Test = () => {
  return (
    <div>
      <Row>
        <Col span={12}>
          {/* Nội dung cột 1 */}
          <h1>Cột 1</h1>
        </Col>
        <Col span={12}>
          {/* Nội dung cột 2 */}
          <h1>Cột 2</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* Nội dung cột đơn */}
          <h1>Hàng 2</h1>
        </Col>
      </Row>
    </div>
  )
}

export default Test
