import React from 'react'
import { Button } from 'antd'

const ContactBasic = ({ contact }) => {
  if (!contact) return null

  // Split theo từng dòng (xuống dòng)
  const contactLines = contact.split('\n')

  const renderContactLink = (key, value) => {
    let link = ''
    let label = ''

    // Kiểm tra loại liên hệ
    if (key === 'ZALO') {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent)
      link = isMobile
        ? `https://zalo.me/${value}` // Zalo trên Mobile
        : `https://chat.zalo.me/?phone=${value}` // Zalo trên Desktop
      label = `Zalo: ${value}`
    } else if (key === 'FACEBOOK') {
      link = `https://facebook.com/${value}`
      label = `Facebook: ${value}`
    }

    // Render Button mở liên kết trong tab mới
    return (
      <Button
        type="link"
        onClick={() => window.open(link, '_blank')}
        key={key}
        style={{ marginBottom: '8px' }} // Thêm khoảng cách giữa các nút
      >
        {label}
      </Button>
    )
  }

  return (
    <div>
      {contactLines.map((line, index) => {
        const [key, value] = line.split(':')
        if (!key || !value) return null // Nếu không đúng định dạng key:value thì bỏ qua
        return renderContactLink(key.trim(), value.trim())
      })}
    </div>
  )
}

export default ContactBasic
