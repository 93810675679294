// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ImportExcelPage.css */
.row-warning {
  background-color: #ffecb3; /* Màu vàng cảnh báo */
}

.row-success {
  background-color: #d4edda; /* Màu xanh thành công */
}

.row-danger {
  background-color: #f8d7da; /* Màu đỏ nguy hiểm */
}
`, "",{"version":3,"sources":["webpack://./src/pages/ImportProspectPage/ImportExcelPage.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,yBAAyB,EAAE,sBAAsB;AACnD;;AAEA;EACE,yBAAyB,EAAE,wBAAwB;AACrD;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;AAClD","sourcesContent":["/* ImportExcelPage.css */\n.row-warning {\n  background-color: #ffecb3; /* Màu vàng cảnh báo */\n}\n\n.row-success {\n  background-color: #d4edda; /* Màu xanh thành công */\n}\n\n.row-danger {\n  background-color: #f8d7da; /* Màu đỏ nguy hiểm */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
