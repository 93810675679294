import { getToken } from '../helpers'
import { API } from '../constant'

export const createLog = async logData => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/logs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        data: logData
      })
    })

    if (!response.ok) {
      throw new Error('Failed to create log')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error creating log:', error)
    throw error
  }
}
