// # src/helpers.js

import { AUTH_TOKEN } from './constant'

export const getToken = () => {
  var token = localStorage.getItem(AUTH_TOKEN)
  if (token) {
    return token
  } else {
    // navigate('/signin')
  }
}

export const setToken = token => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN)
}

// Lấy thông tin user từ localStorage hoặc sessionStorage
export const getUserId = () => {
  const user =
    JSON.parse(localStorage.getItem('userData')) ||
    sessionStorage.getItem('userData')
  return user ? user.id : null
}
