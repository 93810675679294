import { getToken } from '../helpers'
import { API } from '../constant'

// Function to fetch customer details by ID
export const getCustomerById = async customerId => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/customers/${customerId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (!response.ok) {
      throw new Error('Failed to fetch customer details')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching customer details:', error)
    throw error
  }
}

// Function to update customer details
export const updateCustomer = async (customerId, updatedData) => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/customers/${customerId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(updatedData)
    })

    if (!response.ok) {
      throw new Error('Failed to update customer')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error updating customer details:', error)
    throw error
  }
}

// Function to delete a customer
export const deleteCustomer = async customerId => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/customers/${customerId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (!response.ok) {
      throw new Error('Failed to delete customer')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error deleting customer:', error)
    throw error
  }
}

// Function to check for duplicate phone numbers
export const checkPhones = async phones => {
  // console.log('checkPhones -> phones', phones)
  try {
    const token = getToken()
    const response = await fetch(`${API}/customers/check-phones`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ phones })
    })

    if (!response.ok) {
      throw new Error('Failed to check phone numbers')
    }

    const data = await response.json()
    // console.log('checkPhones -> data', data)
    return data
  } catch (error) {
    console.error('Error checking phone numbers:', error)
    throw error
  }
}

export const mergeCustomer = async (parentId, childId) => {
  try {
    const token = getToken()
    const response = await fetch(`${API}/customers/merge`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        parentId: parentId,
        childId: childId
      })
    })

    if (!response.ok) {
      throw new Error('Failed to merge customers')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error merging customers:', error)
    throw error
  }
}

// Function to add a new customer
export const addCustomer = async newCustomerData => {
  try {
    const token = getToken() // Lấy token từ hàm getToken
    const response = await fetch(`${API}/customers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}` // Thêm Bearer token vào headers
      },
      body: JSON.stringify(newCustomerData) // Gửi dữ liệu khách hàng
    })

    if (!response.ok) {
      throw new Error('Failed to add customer')
    }

    const data = await response.json() // Parse response từ API
    return data
  } catch (error) {
    console.error('Error adding customer:', error)
    throw error
  }
}
