import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import axios from 'axios'
import { API } from '../../constant'
import { getToken } from '../../helpers'

export default function Products() {
  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

  const fetchProducts = async (page, pageSize) => {
    try {
      const response = await axios.get(`${API}/products`, {
        params: {
          'pagination[page]': page,
          'pagination[pageSize]': pageSize
        },
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
      const { data, meta } = response.data
      setProducts(data)
      setPagination({
        ...pagination,
        total: meta.pagination.total,
        current: page,
        pageSize: pageSize
      })
    } catch (error) {
      console.error('Failed to load products', error)
    }
  }

  useEffect(() => {
    fetchProducts(pagination.current, pagination.pageSize)
  }, [])

  const handleTableChange = pagination => {
    fetchProducts(pagination.current, pagination.pageSize)
  }

  const columns = [
    {
      title: 'KiotViet Code',
      dataIndex: ['attributes', 'kiotVietCode'],
      key: 'kiotVietCode'
    },
    { title: 'Name', dataIndex: ['attributes', 'name'], key: 'name' },
    { title: 'Unit', dataIndex: ['attributes', 'unit'], key: 'unit' },
    {
      title: 'Purchase Price',
      dataIndex: ['attributes', 'purchasePrice'],
      key: 'purchasePrice',
      render: price => `${price.toLocaleString()} VND`
    }
  ]

  return (
    <Table
      dataSource={products}
      columns={columns}
      rowKey={product => product.id}
      pagination={{
        ...pagination,
        position: ['topRight', 'bottomRight'] // This will place the pagination at both the top and bottom
      }}
      onChange={handleTableChange}
    />
  )
}
