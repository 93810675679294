import { Routes, Route } from 'react-router-dom'
import LayoutApp from '../layouts/DefaultLayout'
import Home from '../pages/App/Home'
import Staff from '../pages/App/Staff'
import Customer from '../pages/App/Customer'
import Campaign from '../pages/App/Campaign'
import SyncKiotViet from '../pages/App/SyncKiotViet'
import ActionCampaign from '../pages/App/ActionCampaign'
import CreateCampaign from '../pages/App/CreateCampaign'
import DetailCustomer from '../pages/App/DetailCustomer'
import SignIn from '../pages/SignIn/SignIn'
import SignUp from '../pages/SignUp/SignUp'
import Profile from '../components/Profile/Profile'
import Test from '../pages/App/Test'
import Product from '../pages/App/Product'
import ImportProspectPage from '../pages/ImportProspectPage/ImportProspectPage'
import ProspectPage from '../pages/ProspectPage/Page'
const RouterApp = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutApp />}>
        <Route index element={<Home />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/detail-customer/:id" element={<DetailCustomer />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/sync" element={<SyncKiotViet />} />
        <Route path="/action-campaign" element={<ActionCampaign />} />
        <Route path="/create-campaign" element={<CreateCampaign />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/product" element={<Product />} />
        <Route path="/import-prospect" element={<ImportProspectPage />} />
        <Route path="/prospect" element={<ProspectPage />} />

        {/* <Route path="/diagram" element={<DrawIOEditor />} /> */}
        <Route path="/test" element={<Test />} />
      </Route>
    </Routes>
  )
}

export default RouterApp
