import React, { useState, useEffect } from 'react'
import { Button, Modal, Table, Input, Form, message, Popover } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { API } from '../../constant'
import { getToken, getUserId } from '../../helpers'
import './ImportExcelPage.css'
import { Row, Col } from 'antd'
import ContactBasic from './ContactBasic'
import { mergeCustomer } from '../../api/customerApi'
import { createLog } from '../../api/logApi'

const ProspectActions = ({ prospect }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(true)
  const [phone, setPhone] = useState('') // Quản lý số điện thoại
  const [note, setNote] = useState('') // Quản lý ghi chú
  const [existingPhones, setExistingPhones] = useState([]) // Số điện thoại của khách hàng hiện tại
  //   const [existingPhones, setExistingPhones] = useState([]) // Số điện thoại hiện có
  const [showPhoneInput, setShowPhoneInput] = useState(false) // Trạng thái hiển thị input thêm số điện thoại
  const [showPopover, setShowPopover] = useState(false) // Trạng thái Popover

  // Hàm hiển thị Popover
  const handleVisibleChange = visible => {
    setShowPopover(visible)
  }
  const [form] = Form.useForm()

  // Hàm hiển thị modal
  const showModal = () => {
    setIsModalVisible(true)
    fetchLogs() // Gọi API để lấy logs khi mở modal
    fetchCustomerPhones() // Lấy số điện thoại của khách hàng
  }

  // Hàm ẩn modal
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // Hàm gọi API để lấy logs từ Strapi
  const fetchLogs = async () => {
    const getAuthToken = () => `Bearer ${getToken()}`
    const token = getAuthToken()

    try {
      const response = await axios.get(
        `${API}/logs?filters[idObject][$eq]=${prospect.id}&filters[nameObject][$eq]=customers&populate=users_permissions_user`,
        {
          headers: {
            Authorization: token
          }
        }
      )
      setLogs(response.data.data) // Lưu logs vào state
      //   console.log('Logs:', response.data.data)
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch logs:', error)
      setLoading(false)
    }
  }
  const handleMergeCustomer = async existingCustomer => {
    // console.log('Existing customer:', existingCustomer, prospect)
    try {
      const response = await mergeCustomer(existingCustomer.id, prospect.id)
      //   console.log('Merge customer response:', response)
      createLog({
        type: 'process',
        content: `Khách hàng ${prospect.attributes.name} đã được merge vào khách hàng ${existingCustomer.name}`,
        idObject: prospect.id.toString(),
        nameObject: 'customers',
        level: 'info',
        customers: [prospect.id, existingCustomer.id]
      })
      message.success('Merge khách hàng thành công!')
    } catch (error) {
      console.error('Error merging customers:', error)
      message.error('Có lỗi xảy ra khi merge khách hàng')
    }
  }
  // Lấy danh sách số điện thoại của khách hàng
  const fetchCustomerPhones = () => {
    setExistingPhones(prospect.attributes.phones || [])
  }

  // Kiểm tra xem số điện thoại đã tồn tại chưa
  const checkPhoneExists = async phoneNumber => {
    const getAuthToken = () => `Bearer ${getToken()}`
    const token = getAuthToken()

    try {
      const response = await axios.post(
        `${API}/customers/check-phones`,
        { phones: [phoneNumber] },
        {
          headers: {
            Authorization: token
          }
        }
      )
      //   console.log('Check phone response:', response.data)

      if (response.data.length > 0) {
        const existingCustomer = response.data[0]
        return existingCustomer
      }
      return null
    } catch (error) {
      console.error('Error checking phone number:', error)
      return null
    }
  }

  // Hàm để thêm số điện thoại mới vào khách hàng
  const addPhone = async () => {
    if (!phone) {
      message.error('Vui lòng nhập số điện thoại!')
      return
    }

    // Kiểm tra số điện thoại
    const existingCustomer = await checkPhoneExists(phone)
    // console.log('Existing customer:', existingCustomer)

    if (existingCustomer) {
      Modal.confirm({
        title: `Số điện thoại này đã thuộc về khách hàng ${existingCustomer.name}`,
        content: 'Bạn có muốn MERGE khách hàng không?',
        onOk: () => {
          // Gửi yêu cầu merge khách hàng (API merge sẽ được xử lý sau)
          handleMergeCustomer(existingCustomer)
          //   message.success('Gửi yêu cầu merge khách hàng thành công!')
        },
        onCancel: () => {
          message.info('Bạn đã hủy yêu cầu merge.')
        }
      })
      return
    }

    const getAuthToken = () => `Bearer ${getToken()}`
    const token = getAuthToken()

    try {
      // Kiểm tra nếu prospect có sẵn số điện thoại
      //   console.log('Prospect:', prospect)
      const updatedPhones = prospect.attributes.phones
        ? [...prospect.attributes.phones, { numberPhone: phone }] // Nếu có, thêm số mới vào
        : [{ numberPhone: phone }] // Nếu không có, tạo mảng mới chỉ với số điện thoại mới
      //   console.log('Updated phones:', updatedPhones)
      await axios.put(
        `${API}/customers/${prospect.id}`,
        {
          data: {
            phones: updatedPhones // Cập nhật danh sách số điện thoại
          }
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
      message.success('Số điện thoại đã được thêm!')
      setPhone('') // Xoá input sau khi thêm số điện thoại
      fetchCustomerPhones() // Cập nhật lại danh sách số điện thoại sau khi thêm
    } catch (error) {
      message.error('Có lỗi xảy ra khi thêm số điện thoại')
    }
  }

  // Hàm để thêm ghi chú
  const addNote = async () => {
    const token = `Bearer ${getToken()}` // Lấy token từ helpers
    const userId = getUserId() // Lấy ID người dùng hiện tại từ helpers

    if (!note) {
      message.error('Vui lòng nhập ghi chú!')
      return
    }

    try {
      await axios.post(
        `${API}/logs?populate=users_permissions_user`,
        {
          data: {
            type: 'note',
            content: note,
            idObject: prospect.id.toString(), // Gán ID khách hàng hiện tại
            nameObject: 'customers',
            level: 'note',
            timeLog: new Date(),
            users_permissions_user: userId, // Gán ID người dùng hiện tại
            customers: prospect.id // Gán ID của khách hàng vào trường customers
          }
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
      message.success('Ghi chú đã được thêm!')
      setNote('') // Xoá input sau khi thêm ghi chú
      fetchLogs() // Cập nhật lại logs
    } catch (error) {
      message.error('Có lỗi xảy ra khi thêm ghi chú')
    }
  }

  // Hàm chuyển khách hàng từ PROSPECT sang LEAD
  const convertToLead = async () => {
    const getAuthToken = () => `Bearer ${getToken()}`
    const token = getAuthToken()

    try {
      // Tạo log khi chuyển khách hàng
      await axios.post(
        `${API}/logs`,
        {
          data: {
            type: 'process',
            content: `${prospect.attributes.name} đã được thẩm định từ PROSPECT sang LEAD`,
            idObject: prospect.id.toString(),
            nameObject: 'customers',
            level: 'process',
            timeLog: new Date(),
            users_permissions_users: getUserId(),
            customers: prospect.id
          }
        },
        {
          headers: {
            Authorization: token
          }
        }
      )

      // Cập nhật type của khách hàng thành LEAD
      await axios.put(
        `${API}/customers/${prospect.id}`,
        {
          data: {
            type: 'LEAD',
            status: 'idle'
          }
        },
        {
          headers: {
            Authorization: token
          }
        }
      )

      message.success('Khách hàng đã được chuyển sang LEAD!')
      setIsModalVisible(false)
    } catch (error) {
      message.error('Có lỗi xảy ra khi chuyển khách hàng sang LEAD')
    }
  }

  // Cấu hình các cột cho bảng logs
  const logColumns = [
    {
      title: 'Nội dung',
      dataIndex: ['attributes', 'content'],
      key: 'content'
    },
    {
      title: 'Thời gian',
      dataIndex: ['attributes', 'timeLog'],
      key: 'timeLog',
      render: text => moment(text).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      title: 'Mức độ',
      dataIndex: ['attributes', 'level'],
      key: 'level'
    },
    {
      title: 'Người thực hiện',
      dataIndex: [
        'attributes',
        'users_permissions_user',
        'data',
        'attributes',
        'username'
      ], // Đường dẫn đầy đủ
      key: 'users_permissions_users'
    }
  ]

  // Hàm để thay đổi màu toàn bộ row dựa trên level
  const rowClassName = record => {
    const level = record.attributes.level
    if (level === 'success') return 'row-success'
    if (level === 'danger') return 'row-danger'
    if (level === 'warning') return 'row-warning'
    if (level === 'info') return 'row-info'
    return ''
  }

  return (
    <div>
      <Button type="primary" onClick={showModal} style={{ marginRight: 8 }}>
        Thẩm định
      </Button>
      {/* <Button
        type="danger"
        onClick={() => console.log('Deleting prospect:', prospect)}
      >
        Delete
      </Button> */}

      {/* Modal để hiển thị thông tin khách hàng và logs */}
      <Modal
        title={`${prospect.attributes.name}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <div>
          <Row gutter={16}>
            <Col span={6}>
              <p>
                <strong>Tên: </strong> {prospect.attributes.name} <br />
                <strong>Ngày tạo: </strong>{' '}
                {moment(prospect.attributes.createdAt).format('DD/MM/YYYY')}
              </p>
            </Col>

            <Col span={6}>
              {prospect.attributes.contactBasic ? (
                <>
                  <strong>Liên hệ cơ bản: </strong>
                  <ContactBasic contact={prospect.attributes.contactBasic} />
                  {/* {prospect.attributes.contactBasic} */}
                </>
              ) : (
                <p>Chưa có thông tin liên hệ cơ bản</p>
              )}
            </Col>

            <Col span={8}>
              <h4>Danh sách số điện thoại:</h4>
              {existingPhones.length > 0 ? (
                <ul>
                  {existingPhones.map((phoneObj, index) => (
                    <li key={index}>{phoneObj.numberPhone}</li>
                  ))}
                </ul>
              ) : (
                <p>Không có số điện thoại nào</p>
              )}
            </Col>

            <Col span={4}>
              {/* Sử dụng Popover để thêm số điện thoại */}
              <Popover
                content={
                  <div>
                    <Form.Item label="Số điện thoại mới">
                      <Input
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        placeholder="Nhập số điện thoại mới"
                        style={{ width: '80%' }}
                      />
                      <Button
                        type="primary"
                        onClick={addPhone} // Thêm logic xử lý khi thêm số điện thoại
                        style={{ marginTop: '10px' }}
                      >
                        Thêm
                      </Button>
                    </Form.Item>
                  </div>
                }
                title="Thêm số điện thoại mới"
                trigger="click"
                open={showPopover}
                onOpenChange={handleVisibleChange}
              >
                <Button type="link" style={{ marginLeft: '8px' }}>
                  Thêm
                </Button>
              </Popover>
            </Col>
          </Row>

          <hr />

          {/* Form thêm ghi chú */}
          <Row gutter={16}>
            <Col span={16}>
              <Form form={form}>
                <Row gutter={16}>
                  {/* Ghi chú thẩm định */}
                  <Col span={24}>
                    <h4>Ghi chú thẩm định</h4>
                    <Form.Item>
                      <Input.TextArea
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        placeholder="Nhập ghi chú"
                        rows={4}
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      onClick={addNote}
                      style={{ float: 'right' }} // Canh phải nút Thêm ghi chú
                    >
                      Thêm ghi chú
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={8}>
              <Button type="primary" onClick={convertToLead} danger>
                Chuyển sang LEAD
              </Button>
            </Col>
          </Row>

          <h3>Ghi chú:</h3>
          <Table
            columns={logColumns}
            dataSource={logs}
            rowKey="id"
            loading={loading}
            pagination={false}
            rowClassName={rowClassName}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ProspectActions
