import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { getCustomerById, updateCustomer } from '../../api/customerApi'
import { getToken } from '../../helpers'

const DetailCustomer = () => {
  const { id } = useParams()
  const [customer, setCustomer] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const token = getToken()
        const response = await getCustomerById(id)
        console.log(response)
        setCustomer(response.data)
      } catch (error) {
        console.error('Failed to fetch customer:', error)
      }
    }
    fetchCustomer()
  }, [id])

  const onFinish = async values => {
    setLoading(true)

    try {
      const token = getToken()
      await updateCustomer(id, values, token)
      // Handle success, e.g. show notification or redirect
    } catch (error) {
      console.error('Failed to update customer:', error)
    }

    setLoading(false)
  }

  if (!customer) {
    return <div>Loading...</div>
  }

  const initialValues = customer.attributes

  return (
    <div>
      <h1>Edit Customer</h1>
      <Form onFinish={onFinish} initialValues={initialValues}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="code" label="Code">
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Input />
        </Form.Item>
        <Form.Item name="debt" label="Debt">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>
        <Form.Item name="totalInvoiced" label="Total Invoiced">
          <Input />
        </Form.Item>
        <Form.Item name="gender" label="Gender">
          <Input />
        </Form.Item>
        <Form.Item name="birthDate" label="Birth Date">
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default DetailCustomer
