import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, Select, Button, Input, Row, Col } from 'antd'
import moment from 'moment'
import { API } from '../../constant'
import { getToken } from '../../helpers'
import ProspectActions from './ProspectActions' // Component cho cột Action

const ProspectPage = () => {
  const [prospects, setProspects] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageSize, setPageSize] = useState(20) // Số lượng hàng mỗi trang
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCustomers, setTotalCustomers] = useState(0) // Tổng số khách hàng
  const [search, setSearch] = useState('') // Dữ liệu filter hiện tại

  useEffect(() => {
    // Gọi API để lấy danh sách khách hàng PROSPECT
    const fetchProspects = async () => {
      const getAuthToken = () => `Bearer ${getToken()}`

      const token = getAuthToken()
      try {
        const response = await axios.get(
          `${API}/customers?populate=phones&filters[type][$eq]=PROSPECT&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}&filters[name][$contains]=${search}`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        setProspects(response.data.data) // Lưu dữ liệu vào state
        setTotalCustomers(response.data.meta.pagination.total) // Lưu tổng số khách hàng
      } catch (error) {
        console.error('Failed to fetch prospects:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchProspects()
  }, [pageSize, currentPage, search]) // Gọi lại API khi thay đổi pageSize, currentPage, hoặc search

  // Hàm xử lý khi thay đổi số lượng hàng hiển thị
  const handlePageSizeChange = value => {
    setPageSize(value)
    setCurrentPage(1) // Đặt lại trang về 1 khi thay đổi pageSize
  }

  // Định nghĩa các cột cho bảng
  const columns = [
    {
      title: 'Name', // Tên cột
      dataIndex: ['attributes', 'name'], // Dữ liệu hiển thị là tên khách hàng
      key: 'name'
    },
    {
      title: 'Created At', // Tên cột
      dataIndex: ['attributes', 'createdAt'], // Dữ liệu ngày tạo
      key: 'createdAt',
      render: text => moment(text).format('DD/MM/YYYY') // Sử dụng moment để định dạng ngày
    },
    {
      title: 'Action', // Cột hành động
      key: 'action',
      render: (text, record) => (
        <ProspectActions prospect={record} /> // Component cho các hành động
      )
    }
  ]

  return (
    <div>
      <h1>Prospect Customers</h1>

      {/* Component Filter */}
      {/* <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input
            placeholder="Search by name"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={() => setCurrentPage(1)}>
            Search
          </Button>
        </Col>
      </Row> */}

      {/* Thông tin tổng số khách hàng */}
      <div style={{ marginTop: 20 }}>
        <h3>Tổng tiềm năng: {totalCustomers}</h3>
      </div>

      {/* Bảng khách hàng */}
      <Table
        columns={columns}
        dataSource={prospects}
        rowKey="id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCustomers,
          onChange: page => setCurrentPage(page),
          showSizeChanger: true,
          pageSizeOptions: ['20', '30', '50', '100'],
          onShowSizeChange: (current, size) => handlePageSizeChange(size),
          position: ['topLeft', 'bottomLeft'] // Phân trang ở cả trên và dưới bảng
        }}
      />
    </div>
  )
}

export default ProspectPage
