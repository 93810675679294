// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Màu cho các dòng dựa trên level */
.row-success {
  background-color: #dff0d8; /* Xanh lá nhạt cho success */
}

.row-danger {
  background-color: #f2dede; /* Đỏ nhạt cho danger */
}

.row-warning {
  background-color: #fcf8e3; /* Vàng nhạt cho warning */
}

.row-info {
  background-color: #d9edf7; /* Xanh lam nhạt cho info */
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProspectPage/ImportExcelPage.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,yBAAyB,EAAE,6BAA6B;AAC1D;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;AACpD;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA;EACE,yBAAyB,EAAE,2BAA2B;AACxD","sourcesContent":["/* Màu cho các dòng dựa trên level */\n.row-success {\n  background-color: #dff0d8; /* Xanh lá nhạt cho success */\n}\n\n.row-danger {\n  background-color: #f2dede; /* Đỏ nhạt cho danger */\n}\n\n.row-warning {\n  background-color: #fcf8e3; /* Vàng nhạt cho warning */\n}\n\n.row-info {\n  background-color: #d9edf7; /* Xanh lam nhạt cho info */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
